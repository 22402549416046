<template>
    <div class="tiepai-management">
        <!-- 贴牌管理 -->
        <div class="section">

            <el-form :model="tiepaiForm" class="tiepai-form" label-width="120px">
                <h2 class="section-title">贴牌信息</h2>
                <el-form-item label="贴牌名称">
                    <el-input v-model="tiepaiForm.tiepai_name" placeholder="请输入贴牌名称" class="form-input" />
                </el-form-item>
                <el-form-item label="贴牌头像">
                    <div class="avatar-upload-container">
                        <el-input v-model="tiepaiForm.tiepai_head" placeholder="请输入贴牌头像 URL" class="form-input" />
                        <el-button type="success" @click="handleUploadAvatar">
                            <el-icon>
                                <Upload />
                            </el-icon> 上传头像
                        </el-button>
                    </div>
                </el-form-item>
                <el-form-item label="代理域名">
                    <el-input v-model="tiepaiForm.agent_host" placeholder="请输入代理域名" disabled class="form-input" />
                </el-form-item>

                <el-form-item class="form-buttons">
                    <el-button type="primary" @click="updateTiepaiInfo">保存修改</el-button>
                </el-form-item>
            </el-form>
        </div>

        <el-divider></el-divider> <!-- 分割线 -->

        <!-- 小程序管理 -->
        <div class="section">

            <el-form :model="miniAppForm" ref="miniAppFormRef" class="mini-app-form" label-width="120px">
                <h2 class="section-title">小程序管理</h2>
                <el-form-item label="APPID">
                    <el-input v-model="miniAppForm.app_id" placeholder="请输入APPID" class="form-input" />
                </el-form-item>
                <el-form-item label="APP Secret">
                    <el-input v-model="miniAppForm.app_secret" placeholder="请输入APP Secret" class="form-input" />
                </el-form-item>
                <el-form-item label="上传密钥">
                    <el-input type="textarea" v-model="miniAppForm.upload_key" placeholder="请输入上传密钥" class="form-input"
                        :rows="4" resize="none" />
                </el-form-item>

                <el-form-item label="接口地址">
                    <el-input v-model="miniAppForm.app_url" placeholder="请输入接口地址" class="form-input" />
                </el-form-item>
                <el-form-item class="form-buttons">
                    <el-button type="primary" @click="updateMiniAppInfo">保存修改</el-button>
                    <el-button type="success" @click="handleUploadCode" :loading="uploading">上传代码</el-button>
                </el-form-item>
            </el-form>
        </div>

        <el-divider></el-divider> <!-- 分割线 -->

        <!-- H5 访问 -->
        <div class="section">

            <el-form class="mini-app-form" label-width="120px">
                <h2 class="section-title">页面端访问</h2>
                <el-form-item label="H5 地址">
                    <el-input :value="h5Address" readonly class="form-input" />
                </el-form-item>
            </el-form>
            <el-form class="mini-app-form" label-width="120px">
                <el-form-item label="PC 地址">
                    <el-input :value="pcAddress" readonly class="form-input" />
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { ElMessage, ElMessageBox, ElDivider } from 'element-plus';
import { auth } from '@/utils/auth.js'; // 引入 auth.js
import config from '@/config.js'; // 引入配置文件
import { beforeUpload, uploadToOSS, getUploadSignature } from '@/utils/uploadUtils';
import { Upload } from '@element-plus/icons-vue';


export default {
    name: 'TiepaiManagement',
    components: {
        ElDivider,
        Upload, // 注册图标组件
    },
    data() {
        return {
            username: '', // 当前登录用户
            uploading: false, // 上传代码时的加载状态
            tiepaiForm: {
                tiepai_name: '',
                tiepai_head: '',
                agent_host: '',
            },
            miniAppForm: {
                app_id: '',
                app_secret: '',
                upload_key: '',
                app_url: '',
            },
            h5Address: `${config.BASE_URL}/store`, // 自动计算 H5 地址
            pcAddress: `${config.BASE_URL}/pc`, // 自动计算 H5 地址
        };
    },
    methods: {

        async handleUploadAvatar() {
            try {
                // 打开文件选择器
                const fileInput = document.createElement('input');
                fileInput.type = 'file';
                fileInput.accept = 'image/*'; // 限制为图片文件
                fileInput.onchange = async (event) => {
                    const file = event.target.files[0];
                    if (!file) return;

                    // 校验文件
                    const isValid = await beforeUpload(file, {
                        maxSize: 5, // 文件最大大小 5MB
                        allowedTypes: ['image/jpeg', 'image/png', 'image/gif'], // 允许的图片类型
                    });
                    if (!isValid) return;

                    // 获取上传签名
                    const signatureData = await getUploadSignature('image');
                    if (!signatureData) {
                        ElMessage.error("获取上传签名失败");
                        return;
                    }

                    // 上传文件到 OSS
                    const uploadResult = await uploadToOSS(file, signatureData);
                    if (uploadResult.success) {
                        ElMessage.success("头像上传成功");
                        this.tiepaiForm.tiepai_head = uploadResult.url; // 更新头像 URL
                    } else {
                        ElMessage.error("头像上传失败");
                    }
                };
                fileInput.click(); // 触发文件选择
            } catch (error) {
                console.error("上传头像失败:", error);
                ElMessage.error("上传头像失败，请重试");
            }
        },
        /**
         * 获取贴牌信息
         */
        async fetchTiepaiInfo() {
            try {
                const user = auth.getStoredUser();
                if (!user) {
                    this.$message.error('未登录，请重新登录');
                    this.$router.push('/login');
                    return;
                }
                this.username = user.username;

                // 查询贴牌信息
                const tiepaiResponse = await axios.post(
                    `${config.BASE_URL}/agent/api/agent_api.php`,
                    { action: 'query', username: this.username }
                );

                if (tiepaiResponse.data.success) {
                    this.tiepaiForm = {
                        tiepai_name: tiepaiResponse.data.data.tiepai_name || '',
                        tiepai_head: tiepaiResponse.data.data.tiepai_head || '',
                        agent_host: tiepaiResponse.data.data.agent_host || '',
                    };
                } else {
                    this.$message.error(tiepaiResponse.data.message || '查询贴牌信息失败');
                }

                // 查询小程序管理信息
                const miniAppResponse = await axios.post(
                    `${config.BASE_URL}/agent/api/agent_api.php`,
                    { action: 'query_mini', username: this.username }
                );

                if (miniAppResponse.data.success) {
                    this.miniAppForm = {
                        app_id: miniAppResponse.data.data.app_id || '',
                        app_secret: miniAppResponse.data.data.app_secret || '',
                        upload_key: miniAppResponse.data.data.upload_key || '',
                        app_url: miniAppResponse.data.data.app_url || '',
                    };
                } else {
                    // 如果没有数据，也初始化 miniAppForm 为默认值
                    this.miniAppForm = {
                        app_id: '',
                        app_secret: '',
                        upload_key: '',
                        app_url: '',
                    };
                }
            } catch (error) {
                console.error('查询贴牌信息失败:', error);
                this.$message.error('查询贴牌信息失败，请检查网络连接');
                // 即使请求失败，也确保表单渲染
                this.miniAppForm = {
                    app_id: '',
                    app_secret: '',
                    upload_key: '',
                    app_url: '',
                };
            }
        },

        /**
         * 更新贴牌信息
         */
        async updateTiepaiInfo() {
            try {
                const response = await axios.post(
                    `${config.BASE_URL}/agent/api/agent_api.php`,
                    {
                        action: 'update',
                        username: this.username,
                        tiepai_name: this.tiepaiForm.tiepai_name,
                        tiepai_head: this.tiepaiForm.tiepai_head,
                        agent_host: this.tiepaiForm.agent_host,
                    }
                );

                if (response.data.success) {
                    this.$message.success('贴牌信息保存成功');
                } else {
                    this.$message.error(response.data.error || '保存失败');
                }
            } catch (error) {
                console.error('保存贴牌信息失败:', error);
                this.$message.error('保存失败，请检查网络连接');
            }
        },

        /**
         * 更新小程序信息
         */
        async updateMiniAppInfo() {
            try {
                const response = await axios.post(
                    `${config.BASE_URL}/agent/api/agent_api.php`,
                    {
                        action: 'update_mini',
                        username: this.username,
                        app_id: this.miniAppForm.app_id,
                        app_secret: this.miniAppForm.app_secret,
                        upload_key: this.miniAppForm.upload_key,
                        app_url: this.miniAppForm.app_url,
                    }
                );

                if (response.data.success) {
                    this.$message.success('小程序信息保存成功');
                } else {
                    this.$message.error(response.data.error || '保存失败');
                }
            } catch (error) {
                console.error('保存小程序信息失败:', error);
                this.$message.error('保存失败，请检查网络连接');
            }
        },

        /**
         * 上传代码
         */
        async handleUploadCode() {
            console.log('上传代码方法被触发');
            const payload = {
                appid: this.miniAppForm.app_id,
                privateKeyContent: this.miniAppForm.upload_key,
                api_url: this.miniAppForm.app_url,
            };

            try {
                this.uploading = true; // 开始加载动画
                const response = await axios.post('https://djk.huijp.cn/mini/upload.php', payload);
                if (response.data.status === 'success') {
                    ElMessageBox.alert(
                        '恭喜你代码已上传成功，请登录：https://mp.weixin.qq.com/ 进行管理！',
                        '上传成功',
                        { confirmButtonText: '确定' }
                    );
                } else {
                    ElMessage.error(`上传失败: ${response.data.message || '未知错误'}`);
                }
            } catch (error) {
                console.error('上传过程中发生错误:', error);
                ElMessage.error('上传过程中发生错误，请稍后重试！');
            } finally {
                this.uploading = false; // 停止加载动画
            }
        },
    },
    created() {
        const user = auth.getStoredUser();
        if (user && user.username) {
            this.username = user.username;
            this.fetchTiepaiInfo();
        } else {
            this.$message.error('未登录，请重新登录');
            this.$router.push('/login');
        }
    },
};
</script>

<style scoped>
.tiepai-management {
    background-color: #f5f5f5;
    /* 淡灰色背景 */
    padding: 1px;
    /* 上下左右内边距 */
    margin: 1px;
    /* 上下左右外边距 */
    border-radius: 8px;
    /* 圆角边框 */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    /* 轻微阴影 */
}

.section {
    background-color: #fff;
    /* 白色背景 */
    padding: 1px;
    margin-bottom: 1px;
    /* 每个板块之间的间距 */
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    /* 轻微阴影 */
}

.section-title {
    text-align: center;
    /* 标题居中 */
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    /* 标题与内容之间的间距 */
}

.tiepai-form,
.mini-app-form {
    width: 100%;
    max-width: 500px;
    /* 设置表单的最大宽度 */
}

.el-form-item {
    width: 100%;
    max-width: 500px;
    /* 控制单个表单项的最大宽度 */
    margin-bottom: 20px;
    /* 间距 */
}

.el-form-item__label {
    text-align: left;
    /* 使标签靠左 */
}

.el-form-item__content {
    display: flex;
    flex-direction: column;
}

.form-input {
    width: 100%;
    /* 输入框宽度占满容器 */
}

.form-buttons {
    display: flex;
    justify-content: flex-start;
    /* 按钮靠左 */
    gap: 10px;
    /* 按钮之间的间距 */
}

.avatar-upload-container {
    display: flex;
    align-items: center;
    width: 100%;
    /* 确保容器宽度占满父容器 */
}

.avatar-upload-container .el-input {
    flex: 1;
    /* 输入框占据剩余空间 */
    margin-right: 10px;
    /* 输入框和按钮之间的间距 */
}

.avatar-upload-container .upload-button {
    width: 120px;
    /* 固定按钮宽度 */
    background-color: #67c23a;
    /* 绿色背景 */
    border-color: #67c23a;
    /* 绿色边框 */
    color: #fff;
    /* 白色文字 */
}

.avatar-upload-container .upload-button:hover {
    background-color: #5daf34;
    /* 鼠标悬停时的深绿色 */
    border-color: #5daf34;
}

@media (max-width: 768px) {

    .tiepai-form,
    .mini-app-form {
        max-width: 100%;
        /* 在小屏幕上表单宽度占满容器 */
    }

    .el-form-item {
        max-width: 100%;
    }

    .form-input {
        width: 100%;
    }

    .form-buttons {
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    .form-buttons .el-button {
        width: 100%;
    }
}
</style>