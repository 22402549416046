<template>
    <div class="about-us">
        <div class="section">
            <h2 class="section-title">关于我们</h2>
            <el-form :model="aboutForm" label-width="120px">
                <el-form-item label="公司名称">
                    <el-input v-model="aboutForm.company_name" placeholder="请输入公司名称" />
                </el-form-item>


                <el-form-item label="公司LOGO">
                    <div class="avatar-upload-container">
                        <el-input v-model="aboutForm.company_logo" placeholder="请输入公司LOGO URL" class="form-input" />
                        <el-button type="success" @click="handleUploadAvatar">
                            <el-icon>
                                <Upload />
                            </el-icon> 上传LOGO
                        </el-button>
                    </div>
                </el-form-item>

                <el-form-item label="公司介绍">
                    <el-input type="textarea" v-model="aboutForm.company_desc" placeholder="请输入公司介绍" rows="4" />
                </el-form-item>
                <el-form-item label="公司地址">
                    <el-input v-model="aboutForm.company_address" placeholder="请输入公司地址" />
                </el-form-item>
                <el-form-item label="负责人姓名">
                    <el-input v-model="aboutForm.responsible_person" placeholder="请输入负责人姓名" />
                </el-form-item>
                <el-form-item label="联系方式">
                    <el-input v-model="aboutForm.contact_number" placeholder="请输入联系方式" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="saveAboutInfo">保存修改</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import config from '@/config.js';
import { beforeUpload, uploadToOSS, getUploadSignature } from '@/utils/uploadUtils';
import { Upload } from '@element-plus/icons-vue';
import { ElMessage } from 'element-plus';

export default {
    name: 'AboutUs',
    components: {
        Upload, // 注册图标组件
    },
    data() {
        return {
            aboutForm: {
                company_name: '',
                company_logo: '',
                company_desc: '',
                company_address: '',
                responsible_person: '',
                contact_number: '',
            },
            hostUrl: '', // 用于保存获取到的 host_url
        };
    },
    methods: {
        async handleUploadAvatar() {
            try {
                // 打开文件选择器
                const fileInput = document.createElement('input');
                fileInput.type = 'file';
                fileInput.accept = 'image/*'; // 限制为图片文件
                fileInput.onchange = async (event) => {
                    const file = event.target.files[0];
                    if (!file) return;

                    // 校验文件
                    const isValid = await beforeUpload(file, {
                        maxSize: 5, // 文件最大大小 5MB
                        allowedTypes: ['image/jpeg', 'image/png', 'image/gif'], // 允许的图片类型
                    });
                    if (!isValid) return;

                    // 获取上传签名
                    const signatureData = await getUploadSignature('image');
                    if (!signatureData) {
                        ElMessage.error("获取上传签名失败");
                        return;
                    }

                    // 上传文件到 OSS
                    const uploadResult = await uploadToOSS(file, signatureData);
                    if (uploadResult.success) {
                        ElMessage.success("LOGO上传成功");
                        this.aboutForm.company_logo = uploadResult.url; // 更新头像 URL
                    } else {
                        ElMessage.error("LOGO上传失败");
                    }
                };
                fileInput.click(); // 触发文件选择
            } catch (error) {
                console.error("上传LOGO失败:", error);
                ElMessage.error("上传LOGO失败，请重试");
            }
        },
        /**
         * 获取关于我们信息
         */
        async fetchAboutInfo() {
            try {
                const response = await axios.post(`${config.BASE_URL}/agent/api/about_api.php`, {
                    action: 'get',
                    host_url: config.BASE_URL, // 传递 host_url 参数
                });
                if (response.data.success) {
                    this.aboutForm = response.data.data;
                    this.aboutForm.host_url = response.data.data.host_url || config.BASE_URL; // 确保 host_url 存在
                } else {
                    this.$message.error(response.data.message || '获取关于我们信息失败');
                }
            } catch (error) {
                console.error('获取关于我们信息失败:', error);
                this.$message.error('网络错误，请稍后重试');
            }
        },
        /**
         * 保存关于我们信息
         */
        async saveAboutInfo() {
            try {
                const response = await axios.post(`${config.BASE_URL}/agent/api/about_api.php`, {
                    action: 'save',
                    host_url: this.aboutForm.host_url || config.BASE_URL, // 确保 host_url 传递
                    ...this.aboutForm,
                });
                if (response.data.success) {
                    this.$message.success('关于我们信息保存成功');
                } else {
                    this.$message.error(response.data.message || '保存失败');
                }
            } catch (error) {
                console.error('保存关于我们信息失败:', error);
                this.$message.error('网络错误，请稍后重试');
            }
        },

    },
    created() {
        this.fetchAboutInfo();
    },
};
</script>

<style scoped>
.section {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.section-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.avatar-upload-container {
    display: flex;
    align-items: center;
    width: 100%;
    /* 确保容器宽度占满父容器 */
}

.avatar-upload-container .el-input {
    flex: 1;
    /* 输入框占据剩余空间 */
    margin-right: 10px;
    /* 输入框和按钮之间的间距 */
}

.avatar-upload-container .upload-button {
    width: 120px;
    /* 固定按钮宽度 */
    background-color: #67c23a;
    /* 绿色背景 */
    border-color: #67c23a;
    /* 绿色边框 */
    color: #fff;
    /* 白色文字 */
}

.avatar-upload-container .upload-button:hover {
    background-color: #5daf34;
    /* 鼠标悬停时的深绿色 */
    border-color: #5daf34;
}
</style>