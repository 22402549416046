<template>
  <div class="contact-us">
    <div class="section">
      <h2 class="section-title">联系我们</h2>
      <el-form :model="contactForm" label-width="120px">
        <el-form-item label="姓名">
          <el-input v-model="contactForm.name" placeholder="请输入联系人姓名" />
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="contactForm.phone" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item label="微信号">
          <el-input v-model="contactForm.wechat" placeholder="请输入微信号" />
        </el-form-item>
        <el-form-item label="QQ号">
          <el-input v-model="contactForm.qq" placeholder="请输入QQ号" />
        </el-form-item>


        <el-form-item label="二维码">
          <div class="avatar-upload-container">
            <el-input v-model="contactForm.qrcode_url" placeholder="请输入二维码 URL" class="form-input" />
            <el-button type="success" @click="handleUploadAvatar">
              <el-icon>
                <Upload />
              </el-icon> 上传二维码
            </el-button>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="saveContactInfo">保存修改</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import config from '@/config.js';
import { beforeUpload, uploadToOSS, getUploadSignature } from '@/utils/uploadUtils';
import { Upload } from '@element-plus/icons-vue';
import { ElMessage } from 'element-plus';
export default {
  name: 'ContactUs',
  components: {
    Upload, // 注册图标组件
  },
  data() {
    return {
      contactForm: {
        name: '',
        phone: '',
        wechat: '',
        qq: '',
        qrcode_url: '',
        host_url: config.BASE_URL, // 默认值为 config.BASE_URL
      },
    };
  },
  methods: {
    async handleUploadAvatar() {
      try {
        // 打开文件选择器
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*'; // 限制为图片文件
        fileInput.onchange = async (event) => {
          const file = event.target.files[0];
          if (!file) return;

          // 校验文件
          const isValid = await beforeUpload(file, {
            maxSize: 5, // 文件最大大小 5MB
            allowedTypes: ['image/jpeg', 'image/png', 'image/gif'], // 允许的图片类型
          });
          if (!isValid) return;

          // 获取上传签名
          const signatureData = await getUploadSignature('image');
          if (!signatureData) {
            ElMessage.error("获取上传签名失败");
            return;
          }

          // 上传文件到 OSS
          const uploadResult = await uploadToOSS(file, signatureData);
          if (uploadResult.success) {
            ElMessage.success("二维码上传成功");
            this.contactForm.qrcode_url = uploadResult.url; // 更新头像 URL
          } else {
            ElMessage.error("二维码上传失败");
          }
        };
        fileInput.click(); // 触发文件选择
      } catch (error) {
        console.error("上传二维码失败:", error);
        ElMessage.error("上传二维码失败，请重试");
      }
    },
    /**
     * 获取联系我们信息
     */
    async fetchContactInfo() {
      try {
        const response = await axios.post(`${config.BASE_URL}/agent/api/contact_api.php`, {
          action: 'get',
          host_url: this.contactForm.host_url, // 传递 host_url 参数
        });
        if (response.data.success) {
          this.contactForm = {
            ...response.data.data,
            host_url: response.data.data.host_url || config.BASE_URL, // 确保 host_url 存在
          };
        } else {
          this.$message.error(response.data.message || '获取联系我们信息失败');
        }
      } catch (error) {
        console.error('获取联系我们信息失败:', error);
        this.$message.error('网络错误，请稍后重试');
      }
    },
    /**
     * 保存联系我们信息
     */
    async saveContactInfo() {
      try {
        const response = await axios.post(`${config.BASE_URL}/agent/api/contact_api.php`, {
          action: 'save',
          host_url: this.contactForm.host_url || config.BASE_URL, // 确保 host_url 传递
          ...this.contactForm,
        });
        if (response.data.success) {
          this.$message.success('联系我们信息保存成功');
        } else {
          this.$message.error(response.data.message || '保存失败');
        }
      } catch (error) {
        console.error('保存联系我们信息失败:', error);
        this.$message.error('网络错误，请稍后重试');
      }
    },
  },
  created() {
    this.fetchContactInfo();
  },
};
</script>

<style scoped>
.section {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.section-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.avatar-upload-container {
  display: flex;
  align-items: center;
  width: 100%;
  /* 确保容器宽度占满父容器 */
}

.avatar-upload-container .el-input {
  flex: 1;
  /* 输入框占据剩余空间 */
  margin-right: 10px;
  /* 输入框和按钮之间的间距 */
}

.avatar-upload-container .upload-button {
  width: 120px;
  /* 固定按钮宽度 */
  background-color: #67c23a;
  /* 绿色背景 */
  border-color: #67c23a;
  /* 绿色边框 */
  color: #fff;
  /* 白色文字 */
}

.avatar-upload-container .upload-button:hover {
  background-color: #5daf34;
  /* 鼠标悬停时的深绿色 */
  border-color: #5daf34;
}
</style>