<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/* 可以在这里放置全局样式 */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

/* 可选：调整未选中状态的字体大小 */
.el-tabs__item {
  font-size: 14px; /* 未选中时的字体大小 */
  color: #000; /* 默认文字颜色 */
}

/* 全局作用：选中状态 */
.el-tabs__item.is-active {
  color: #ff5c00 !important; /* 选中时的文字颜色 */
  border-bottom: 0px solid #ff5c00 !important; /* 选中时的底部边框颜色 */
  font-weight: bold; /* 选中时的字体加粗 */
  font-size: 14px; /* 选中时的字体大小 */
}

/* 全局作用：悬停状态 */
.el-tabs__item:hover {
  color: #e04b00 !important; /* 悬停时的文字颜色 */
  font-size: 14px; /* 悬停时的字体大小，与选中状态保持一致 */
}

/* 底部激活条颜色 */
.el-tabs__active-bar {
  background-color: #ff5c00 !important; /* 激活条背景颜色 */
}
.v-modal,
.el-overlay {
  /* 让背景有半透明 + 模糊 */
  background-color: rgba(0, 0, 0, 0.3) !important;
  backdrop-filter: blur(10px);
}

/* 调整关闭按钮的大小 */
.el-dialog__headerbtn {
  font-size: 30px !important; /* 调整图标的大小，默认 16px */
  width: 40px !important; /* 按钮宽度 */
  height: 40px !important; /* 按钮高度 */
  top: 10px !important; /* 调整与顶部的距离 */
  right: 10px !important; /* 调整与右侧的距离 */
}

</style>
